import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Login from "./components/login";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from "./components/dashboard";

function App() {
  return (
    <Router>
        <ToastContainer />
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            
            {/* <Route path="/sign-up" element={<SignUp />} /> */}
          </Routes>
    </Router>
  );
}

export default App;
