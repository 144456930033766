import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 4,
    outline: "none",
    borderRadius: '10px'
};


const LogoutModel = ({ open,logout,CloseLogoutModel }) => {
    const handleSubmit =()=>{
        logout()
        CloseLogoutModel()
    }
    return (
        <>
            <Modal
                open={open}
                onClose={CloseLogoutModel}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                    Are you sure you want to log out?
                    </Typography>
                    <Box className="ActionsModel_Btn_Box" style={{marginTop:'10px'}}>

                        <Button onClick={() => handleSubmit()} variant="contained" color="success" className='ActionsModel_Yes_Btn'>
                            Yes
                        </Button>
                        <Button variant="outlined" color="error" className='ActionsModel_No_Btn' onClick={CloseLogoutModel}>
                            No
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}
export default LogoutModel