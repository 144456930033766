import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 4,
    outline: "none",
    borderRadius: '10px'
};


const DeleteModel = ({ selectUserDetails, CloseDeleteModel, DeleteUser, open }) => {
    return (
        <>
            <Modal
                open={open}
                onClose={CloseDeleteModel}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                    Operation can't be undone, Are you sure want to delete the user?
                    </Typography>
                    <Box className="DeleteModel_Btn_Box">

                        <Button startIcon={<DeleteIcon />} onClick={() => DeleteUser(selectUserDetails)} variant="contained" color="error" className='ActionsModel_Yes_Btn'>
                            Delete
                        </Button>
                        <Button variant="outlined" color="error" className='DeleteModel_No_Btn' onClick={CloseDeleteModel}>
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}
export default DeleteModel