import React from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

const Login =()=> {
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    userName: Yup.string().required('User Name is required'),
    password: Yup.string().required('Password is required'),
  });

  const initialValues = {
    userName: '',
    password: '',
  };

  const handleFieldChange = (e, field, form) => {
    form.setFieldValue(field.name, e.target.value);
  };


  const handleSubmit = (values) => {
    // Handle form submission here
    const { password, userName } = values
    if (password === 'Andy@11928' && userName === 'Andy') {
      localStorage.setItem('token','tokan')
      navigate('/dashboard');
    } else {
      toast.error("User Name or Password is Incorrect")
    }
  };
  return (

    <div className="auth-wrapper">
      <div className="auth-inner">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form>
              <h3 className="mb-4">Admin Login</h3>
              <div className="mb-3">
                <label>User Name</label>
                <input type="text" name="userName" className="form-control" placeholder="Enter User Name"
                  onChange={formik.handleChange} />
                <ErrorMessage name="userName" component="div" className="error"/>

              </div>
              <div className="mb-3">
                <label>Password</label>
                <input type="password" name="password" className="form-control" placeholder="Enter password"
                  onChange={formik.handleChange} />
                <ErrorMessage name="password" component="div" className="error" />
              </div>
              <div className="d-grid">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}


export default Login