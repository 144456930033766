import { initializeApp } from '@firebase/app';
import { Firestore, collection, getDocs, getFirestore, query, updateDoc, where } from 'firebase/firestore';
import { useState } from 'react';
import Modal from 'react-modal';
import { firebaseConfig } from '../../utils/Config';
import { toast } from 'react-toastify';
import { Box, Button, TextField } from '@mui/material';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};


const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const usersRef = collection(firestore, 'users');


const UserDetailsMode = ({ closeModal, data, fetchData, setRefresh, refresh }) => {
    const [dealerId, setDealerId] = useState(data.dealerId)

    const updateDataByID = async (id, newData) => {

        const q = query(usersRef, where('id', '==', id));

        try {
            const querySnapshot = await getDocs(q);

            querySnapshot.forEach(async (doc) => {
                const docRef = doc.ref;
                await updateDoc(docRef, newData);
            });

            setRefresh(!refresh)
            toast.success("User Details update Successfully")
        } catch (error) {
            console.error("Error updating documents: ", error);
        }
    };

    const onSubmit = () => {

        // if (dealerId !== "") {

        const newData = {
            // Update the fields you want to change
            "dealerId": dealerId
        };

        updateDataByID(data.id, newData);
        // fetchData()

        closeModal()
        // }else{
        //     toast.error('Please Enter Dealer Id')
        // }

    }

    return (
        <>
            <Modal
                isOpen={true}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className='Modal'>
                    <div>
                        <div className='UserDetailsMode_label_User_Details'>User Details</div>
                    </div>
                    <div>
                        <div className='UserDetailsMode_label'>Name</div>
                        <div>
                            <TextField disabled id="outlined-basic" value={data.name} label="" variant="outlined" className='UserDetailsMode_input' />
                        </div>
                    </div>
                    <div>
                        <div className='UserDetailsMode_label'>Email</div>
                        <TextField disabled id="outlined-basic" value={data.email} label="" variant="outlined" className='UserDetailsMode_input' />
                    </div>

                    <div>
                        <div className='UserDetailsMode_label'>Dealer Id *</div>
                        <TextField value={dealerId} onChange={(e) => setDealerId(e.target.value)} id="outlined-basic" label="" variant="outlined" className='UserDetailsMode_input' />
                    </div>
                    <div>
                        <div className='UserDetailsMode_label'>Dealer Name</div>
                        <TextField disabled id="outlined-basic" value={data.dealerName} label="" variant="outlined" className='UserDetailsMode_input' />

                    </div>
                    <div>
                        <div className='UserDetailsMode_label'>Phone</div>
                        <div>
                            <TextField disabled id="outlined-basic" value={data.phoneNo} label="" variant="outlined" className='UserDetailsMode_input' />
                        </div>
                    </div>


                    <Box className="ActionsModel_Btn_Box">

                        <Button onClick={onSubmit} variant="contained" color="success" className='ActionsModel_Yes_Btn'>
                            Submit
                        </Button>
                        <Button variant="outlined" color="error" className='ActionsModel_No_Btn' onClick={closeModal}>
                            Cancel
                        </Button>
                    </Box>

                </div>
            </Modal>
        </>
    )
}
export default UserDetailsMode