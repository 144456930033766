import { useEffect, useState } from 'react'
import { firebaseConfig } from '../utils/Config'
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore'
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'
import UserDetailsMode from './Model/UserDetailsMode'
import { postSendEmailAPI } from '../services/sendEmail'
import { toast } from 'react-toastify'
import {
  DataGrid,
  GridOverlay,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from '@mui/x-data-grid'
import { IconButton, Menu, MenuItem, Switch } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import closeImg from '../assets/img/close.svg'
import logo from '../assets/img/logo.png'
import ActionsModel from './Model/ActionsModel'
import LogoutModel from './Model/LogoutModel'
import DeleteModel from './Model/DeleteModel'
import moment from 'moment/moment'

const app = initializeApp(firebaseConfig)
const firestore = getFirestore(app)
const usersRef = collection(firestore, 'users')

const CustomActionsCell = ({
  params,
  DeleteModelOpen,
  EditModelOpen,
  disableError,
  setSelectUserDetails,
  OpenActionModel,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            EditModelOpen(params.row)
            handleClose()
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (params.row.dealerId === '') {
              disableError()
            } else {
              setSelectUserDetails(params.row)
              OpenActionModel()
            }
            handleClose()
          }}
        >
          {params.row.status === 0
            ? 'Approve'
            : params.row.status === 1
              ? 'Deactivate'
              : 'Active'}
        </MenuItem>
        {/* {params.row.isDeleteRequest ? */}
        <MenuItem
          onClick={() => {
            DeleteModelOpen(params.row)
            handleClose()
            setSelectUserDetails(params.row)
          }}
        >
          Delete
        </MenuItem>
        {/* : null} */}
      </Menu>
    </div>
  )
}

const CustomLoadingOverlay = () => (
  <GridOverlay>
    <div class="loader"></div>
  </GridOverlay>
)

const Dashboard = () => {
  const navigate = useNavigate()
  const [searchInput, setSearchInput] = useState('')
  const [refresh, setRefresh] = useState(false)
  const [selectUserDetails, setSelectUserDetails] = useState()
  const [loading, setLoading] = useState(true)
  const [isActive, setIsActive] = useState(false)
  const [model, setModel] = useState({
    open: false,
    data: '',
    ActionModel: false,
    LogoutModel: false,
    DeleteModel: false,
  })
  const [allData, setAllData] = useState([])
  const [data, setData] = useState([])

  const fetchData = async () => {
    try {
      setLoading(true)
      const querySnapshot = await getDocs(collection(firestore, 'users'))
      const docs = querySnapshot.docs.map(doc => doc.data())
      const newUserData = []
      docs.map((item => {
        if (item?.createdDate) {
          newUserData.push({
            ...item, createdDate: moment(item?.createdDate, "MM/DD/YYYY").format("YYYY-MM-DD")
          })
        } else {
          newUserData.push(item)
        }
      }))
      setData(newUserData)
      setAllData(newUserData)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      fetchData()
    } else {
      navigate('/')
    }
  }, [refresh])

  useEffect(() => {
    if (isActive) {
      const newUserData = []
      allData?.filter(item => item.isDeleteRequest)?.map((item => {
        if (item.createdDate) {
          newUserData.push({
            ...item, createdDate: moment(item.createdDate, "MM/DD/YYYY").format("YYYY-MM-DD")
          })
        } else {
          newUserData.push(item)
        }
      }))
      setData(newUserData)
    } else {
      const newUserData = []
      allData?.map((item => {
        if (item?.createdDate) {
          newUserData.push({
            ...item, createdDate: moment(item?.createdDate, "MM/DD/YYYY").format("YYYY-MM-DD")
          })
        } else {
          newUserData.push(item)
        }
      }))
      setData(newUserData)
    }
  }, [isActive])

  const logout = () => {
    localStorage.removeItem('token')
    navigate('/')
  }
  const updateDataByID = async (id, newData) => {
    const q = query(usersRef, where('id', '==', id))

    try {
      const querySnapshot = await getDocs(q)

      querySnapshot.forEach(async doc => {
        const docRef = doc.ref
        await updateDoc(docRef, newData)
      })

      setRefresh(!refresh)
    } catch (error) {
      console.error('Error updating documents: ', error)
    }
  }
  const sendMail = (email, name, password, dataItem) => {
    const payload = {
      ToEmail: email,
      Subject: '[MAZO app] Your sign up request has been approved! ',
      MazoUserData: dataItem,
      MAZOEmailType: -1,
      HtmlContent: `
                    <div style="font-size:16px">
                        <div>
                        <div>Hello  ${name},</div><br>
                        <div>Your account has been activated now, please use below username and password for login into mobile app,</div>
                        <br>
                        <div>Username -  ${email}</div>
                        <div>Password -  ${password}</div>
                        <br>
                        <br>
                        <div>Thanks,</div>
                        <div>Team MAZO</div>
                        <div>
                    </div>
                    `,
    }
    postSendEmailAPI(payload)
      .then(res => {
        toast.success('Email send successfully')
      })
      .catch(err => {
        toast.error(err?.data)
      })
  }
  const ActiveUserBtn = async dataItem => {
    const newData = {
      // Update the fields you want to change
      status: 1,
    }

    updateDataByID(dataItem.id, newData)
    sendMail(dataItem.email, dataItem.name, dataItem.password, dataItem)
    // fetchData()
    CloseActionModel()
    toast.success('User Active Successfully')

  }
  const DeActiveUserBtn = async id => {
    const newData = {
      // Update the fields you want to change
      status: 2,
    }
    updateDataByID(id, newData)
    // fetchData()
    CloseActionModel()
    toast.success('User Deactivate Successfully')
    
  }

  const DeleteUser = async Data => {
    const querySnapshot = await getDocs(collection(firestore, 'users'))
    querySnapshot.forEach(async doc => {
      if (doc.data().id === Data.id) {
        await deleteDoc(doc.ref)
      }
    })
    
    setRefresh(!refresh)
    CloseDeleteModel()
    // fetchData()
    setIsActive(false)
    toast.success('User Delete Successfully')
  }

  const EditModelOpen = data => {
    setModel({ ...model, open: true, data: data })
  }
  const DeleteModelOpen = data => {
    setModel({ ...model, DeleteModel: true, data: data })
  }
  const disableError = () => {
    toast.error('Please save Dealer Id before active the user')
  }
  const EditModelClose = data => {
    setModel({ ...model, open: false, data: '' })
  }
  const OpenActionModel = () => {
    setModel({ ...model, ActionModel: true })
  }
  const CloseActionModel = () => {
    setModel({ ...model, ActionModel: false })
    setSelectUserDetails()
  }

  const OpenLogoutModel = () => {
    setModel({ ...model, LogoutModel: true })
  }
  const CloseLogoutModel = () => {
    setModel({ ...model, LogoutModel: false })
    setSelectUserDetails()
  }
  const CloseDeleteModel = () => {
    setModel({ ...model, DeleteModel: false })
    setSelectUserDetails()
  }
  const columns = [
    {
      field: 'email',
      headerName: 'Email',
      width: '10%',
      align: 'left',
      flex: 1,
    },
    {
      field: 'dealerName',
      headerName: 'Dealer Name',
      width: '10%',
      align: 'left',
      flex: 1,
    },
    {
      field: 'dealerId',
      headerName: 'Dealer Id',
      width: '10%',
      align: 'left',
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: '50%',
      align: 'left',
      flex: 1,
    },
    {
      field: 'createdDate',
      headerName: 'Created Date',
      width: '50%',
      align: 'left',
      flex: 1,
      type: 'Date',
    },
    {
      field: 'status',
      headerName: 'Status',
      width: '30%',
      align: 'left',
      flex: 1,
      renderCell: params => {
        return params.row.status === 0
          ? 'Pending'
          : params.row.status === 1
            ? 'Active'
            : 'Deactivate'
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,

      renderCell: params => (
        <CustomActionsCell
          params={params}
          DeleteModelOpen={DeleteModelOpen}
          EditModelOpen={EditModelOpen}
          disableError={disableError}
          ActiveUserBtn={ActiveUserBtn}
          DeActiveUserBtn={DeActiveUserBtn}
          setSelectUserDetails={setSelectUserDetails}
          OpenActionModel={OpenActionModel}
        />
      ),
    },
  ]

  const filterData = data => {
    if (searchInput === '') {
      return data
    } else {
      return data.filter(row => {
        return (
          row?.email?.toLowerCase().includes(searchInput?.toLowerCase()) ||
          row?.dealerName?.toLowerCase().includes(searchInput?.toLowerCase()) ||
          row?.dealerId?.toLowerCase().includes(searchInput?.toLowerCase()) ||
          row?.name?.toLowerCase().includes(searchInput?.toLowerCase()) ||
          row?.actions?.toLowerCase().includes(searchInput?.toLowerCase())
        )
      })
    }
  }
  const CustomToolbar = props => (
    <div>
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
      {/* <SearchBar {...props} /> */}
    </div>
  )

  const removeAllSearch = () => {
    setSearchInput('')
  }
  return (
    <>
      <ActionsModel
        selectUserDetails={selectUserDetails}
        ActiveUserBtn={ActiveUserBtn}
        DeActiveUserBtn={DeActiveUserBtn}
        CloseActionModel={CloseActionModel}
        open={model.ActionModel}
      />
      <LogoutModel
        open={model.LogoutModel}
        logout={logout}
        CloseLogoutModel={CloseLogoutModel}
      />
      <DeleteModel
        selectUserDetails={selectUserDetails}
        open={model.DeleteModel}
        CloseDeleteModel={CloseDeleteModel}
        DeleteUser={DeleteUser}
      />
      {model.open && (
        <UserDetailsMode
          data={model.data}
          closeModal={EditModelClose}
          fetchData={fetchData}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )}

      <div className="Dashboard_table_logo">
        <div>
          <img src={logo} alt='logo' width={'250px'} />
        </div>
        <div className='Dashboard_log_out_btn_box'>
          <div className="Dashboard_text_box">Hello Andy</div>
          <button
            className="Dashboard_log_out_btn"
            variant="warning"
            onClick={OpenLogoutModel}
          >
            Logout
          </button>
        </div>
      </div>
      <div className="Dashboard_table_title">
        <span>{" "}</span>
        <div className="title-btn">
          <form className="form">
            <input
              type="text"
              class="form-control"
              placeholder="Search"
              onChange={e => setSearchInput(e.target.value)}
            />
            <button type="reset" onClick={removeAllSearch}>
              <img src={closeImg} alt="" className="close-img" />
            </button>
          </form>
          <div style={{ marginLeft: '10px', fontSize: '15px' }}>
            Delete Req{' '}
            <Switch checked={isActive} onClick={() => setIsActive(!isActive)} />
          </div>
          <button className="refresh_btn" onClick={() => setRefresh(!refresh)}>
            ↻ Refresh
          </button>
        </div>
      </div>

      <div className="Dashboard_table">
        <DataGrid
          rows={filterData(data)}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 25 },
            },
          }}
          pageSizeOptions={[25, 50, 100, 200]}
          checkboxSelection={false}
          components={{
            LoadingOverlay: CustomLoadingOverlay, // Use the custom loading overlay
          }}
          loading={loading}
          headerClassName="bold-header"
        />
      </div>
    </>
  )
}
export default Dashboard
